import React from 'react'
import Menu from '@/components/Menu.js'

const Home = () =>
{
  const [page,SetPage] = React.useState(1)
  const [active,SetActive] = React.useState('all')

  //默认加载状态
  const [finished,SetFinish] = React.useState(false)
  const [list,SetList] = React.useState([])

  const statuslist = [
    {name:'全部',value:'all'},
    {name:'未开始',value:'0'},
    {name:'开始报名',value:'1'},
    {name:'截至报名',value:'2'},
    {name:'已开始',value:'3'},
    {name:'已结束',value:'4'},
  ]

  let TabChange = (index) =>
  {
    SetActive(statuslist[index]['value'])
    onRefresh()
  }

  //数据请求
  const ProjectLoad = async () =>
  {
    var data = {
      status:active,
      page:page
    }

    var result = await React.$HTTP.POST({
      url:'/project/project/index',
      params:data
    })

    if(result.code == 0)
    {
      SetFinish(true)
      React.$Vant.Toast.fail(result.msg)
      return false
    }

    //渲染数据
    SetList(list.concat(result.data))
    SetPage(page+1)
  }

  //下拉刷新
  const onRefresh = ()=>
  {
    SetFinish(false)
    SetPage(1)
    SetList([])
  }

    return (
        <>
          <link rel="stylesheet" href="/assets/css/index.css" type="text/css" />
    
          <React.$Vant.NavBar
            title='活动列表'
            leftArrow={false}
          />
            
          <div className="banner_box swiper-container">
            <div className='swiper-wrapper' id="banner">
              <React.$Router.NavLink to="/coupon" className="swiper-slide">
                <img  src="/assets/images/banner.png" />
              </React.$Router.NavLink>
            </div>
            <div className="swiper-pagination"></div>     
          </div>

          <React.$Vant.Tabs sticky swipeable onChange={TabChange}>
            {statuslist.map(item =>(
              <React.$Vant.Tabs.TabPane key={item.value} title={item.name}></React.$Vant.Tabs.TabPane>
            ))}  
          </React.$Vant.Tabs>
    
          <div className="project">
            <React.$Vant.PullRefresh onRefresh={onRefresh}>
              <React.$Vant.List offset={10} finished={finished} onLoad={ProjectLoad}>
                {list.map((item,key)=>(
                  <React.$Router.NavLink to="/project/info" state={{id:item.id}} className="item" key={key}>
                      <div className='thumb'>
                        <img src={item.thumb_text} />
                      </div>

                      <div className='info'>
                        <div className='title'>{item.title}</div>
                        <div className='taglist'>
                          <div className='tag'>{item.status_text}</div>
                          <div className='tag'>已有{item.count_text}人参与</div>
                        </div>
                        <div className='limit'>
                          <div className='price'>￥{item.price}</div>
                          <div className='max'>{item.nums == 0 ? '不限人数' : item.nums}</div>
                        </div>
                        <div className='time'>{item.createtime_text}</div>
                      </div>
                  </React.$Router.NavLink>
                ))}
              </React.$Vant.List>  
            </React.$Vant.PullRefresh>
          </div>
    
          <Menu />
        </>
    )
}

export default Home