import React from "react";

class AuthRouter extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {}
    }

    render()
    {
        const AuthLogin = ()=>
        {
            let navigate = React.$Router.useNavigate()

            React.useEffect(()=>{
                var cookie = React.$Cookies.load('business') ? React.$Cookies.load('business') : {}

                if(Object.getOwnPropertyNames(cookie).length <= 0)
                {
                    navigate('/login')
                }
            })
        }

        return (
            <>
                {
                    this.props.auth && <AuthLogin />
                }

                {this.props.component}
            </>
        )
    }
}

export default AuthRouter