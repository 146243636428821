import React from "react";

const Orr = ()=>
{
    const navigate = React.$Router.useNavigate()

    const cookie = React.$Cookies.load('business')
    const [business,SetBusiness] = React.useState(cookie)

    const [page,SetPage] = React.useState(1)
    const [active,SetActive] = React.useState('all')
    const [finished,SetFinish] = React.useState(false)
    const [list,SetList] = React.useState([])

    const statuslist = [
        {name:'全部',value:'all'},
        {name:'未开始',value:'0'},
        {name:'已开始',value:'1'},
        {name:'已结束',value:'2'},
    ]

    const ProjectLoad = async ()=>
    {
        var data = {
            busid:business.id,
            status:active,
            page:page
        }

        var result = await React.$HTTP.POST({
            url:'/projectbusiness/order/order',
            params:data
        })

        if(result.code == 0)
        {
        SetFinish(true)
        React.$Vant.Toast.success({
            message:result.msg,
            duration:500
        })
        return false
        }

        //渲染请求数据
        SetList(list.concat(result.data))
        SetPage(page+1)
    }

    let TabChange = (index)=>
    {
        SetActive(statuslist[index]['value'])
        onRefresh()
    }
    
    const onRefresh = ()=>
    {
        SetFinish(false)
        SetPage(1)
        SetList([])
    }

    //状态按钮
    const ReceiveButton = (item)=>
    {
        if(item.status == 0)
        {
            return (
                <React.$Vant.Button disabled size="small" className="cancel" type='primary'>未开始</React.$Vant.Button>
            )
        }
        if(item.status == 1)
        {
            return (
                <React.$Vant.Button disabled size="small" className="cancel" type='info'>已开始</React.$Vant.Button>
            )
        }
        if(item.status == 2)
        {
            return (
                <React.$Vant.Button disabled size="small" className="cancel" type='warning'>已结束</React.$Vant.Button>
            )
        }
    }

    console.log(list);


    return (
        <>
        <link rel="stylesheet" href="/assets/css/order.css" type="text/css" />

        <React.$Vant.NavBar
            title="我的活动"
            onClickLeft={()=>navigate(-1)}
        />

        <React.$Vant.Tabs sticky swipeable onChange={TabChange}>
            {statuslist.map(item =>(
              <React.$Vant.Tabs.TabPane key={item.value} title={item.name}></React.$Vant.Tabs.TabPane>
            ))}  
        </React.$Vant.Tabs>

        <div className="move_box">
            <div className="move">
                <div className="order_box">
                    <React.$Vant.PullRefresh onRefresh={onRefresh}>
                        <React.$Vant.List offset={5} finished={finished} onLoad={ProjectLoad}>
                        {list.map((item,key)=> {
                                return (
                                    <React.$Router.NavLink to="/project/info" state={{id:item.project.id}}   className="order" key={key}>

                                        <div className="order2" key={key}> 
                                            <div className="order_title">{item.project.title}</div>
                                            <div className="order_text">
                                                <img className="pic" src={item.project.thumb_text} />
                                                <h4>
                                                开始时间：{item.project.createtime_text}<br />
                                                </h4>
                                                <p>￥{(item.total)}</p>
                                            </div>

                                            {ReceiveButton(item)}
                                        </div>
                                        
                                    </React.$Router.NavLink>
                                )
                        })}
                        </React.$Vant.List>
                    </React.$Vant.PullRefresh>
                </div>
            </div>
        </div>
        
        </>
    )
}

export default Orr