import React from "react";
import Menu from '@/components/Menu.js'

const Index = () =>
{

  var business = React.$Cookies.load('business') ? React.$Cookies.load('business') : {}
  
  let navigate = React.$Router.useNavigate()

  var [business,setBusiness] = React.useState(business)
  var [ocount,setOcount] = React.useState(0)
  var [coupons,setCoupons] = React.useState(0)

  React.useEffect(()=>{
    updateinfo()
    orders()
    coulist()
  },[])

  const orders = async()=>
  {
    var result = await React.$HTTP.POST({
      url:'/business/ordercount',
      params:{busid:business.id}
    })
    if(result.code == 0)
    {
      React.$Vant.Toast.fail('获取活动信息失败，请重新登陆');
      return false
    }
    setOcount(result.data)
  }

  const coulist = async()=>
  {
    var result = await React.$HTTP.POST({
      url:'/business/coupon',
      params:{busid:business.id}
    })
    if(result.code == 0)
    {
      React.$Vant.Toast.fail('获取优惠券失败，请重新登录');
      return false
    }
    setCoupons(result.data)
    console.log(result.data);
  }

  const updateinfo = async()=>
  {
    var result = await React.$HTTP.POST({
      url:'/business/cookie',
      params:{busid:business.id}
    })
    if(result.code == 0)
    {
      React.$Vant.Toast.fail('获取用户信息失败，请重新登录');
      navigate('/login')
      return false
    }
    React.$Cookies.save('business',result.data)
    setBusiness(result.data)
  }

  const logout = ()=>
  {
    React.$Vant.Dialog.confirm({
      title:'退出提醒',
      message:'是否确认退出账号'
    })
    .then(()=>{
      React.$Cookies.remove('business')
      navigate('/login')
    })
    .catch(()=>{})
  }

  return (
      <>
        <React.$Vant.NavBar
            title='个人中心'
            leftArrow={false}
          />
  
        <div className="wo">
        <React.$Vant.Image round fit='cover'  src={business.ava_text} />
          <p ><a >{business.nickname}</a></p>
        </div>
  
        <ul className="member">
          <li>
            <a>
              <h2>{business.money}</h2>
              <p>余额</p>
            </a>
          </li>
          <li>
            <React.$Router.NavLink to='/business/order'>
              <h2>{ocount}</h2>
              <p>活动</p>
            </React.$Router.NavLink>
          </li>
          <li>
            <React.$Router.NavLink to='/business/coupon'>
              <h2>{coupons}</h2>
              <p>优惠券</p>
            </React.$Router.NavLink>
          </li>
        </ul>
  
        <ul className="nav">
          <li>
            <React.$Router.NavLink to="/business/profile">
              <span>个人资料</span>
              <i className="am-icon-angle-right"></i>
            </React.$Router.NavLink>
          </li>
          <li>
           <React.$Router.NavLink to='/business/order'>
              <span>我的活动</span>
              <i className="am-icon-angle-right"></i>
            </React.$Router.NavLink>
          </li>
          
          <li>
            <React.$Router.NavLink to='/business/coupon'>
              <span>优惠券</span>
              <i className="am-icon-angle-right"></i>
            </React.$Router.NavLink>
          </li>
        
          <li>
            <a onClick={logout}>
              <span>退出登录</span>
              <i className="am-icon-angle-right"></i>
            </a>
          </li>
        </ul>
  
        <Menu />
      </>
  )
}

export default Index