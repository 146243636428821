import React from 'react'

const Menu = () =>{
    let location = React.$Router.useLocation()
    
    const [active,setActive] = React.useState(location.pathname)

    //初始化
    let navigate = React.$Router.useNavigate()

    let MenuToggle  = (name) =>
    {
        setActive(name)
        navigate(name)
    }

    return (
        <React.$Vant.Tabbar value={active} defaultValue={active} onChange={MenuToggle} activeColor='#f44336' inactiveColor='#000'>
            <React.$Vant.Tabbar.Item name="/" icon={<React.$ICONS.HomeO />}>首页</React.$Vant.Tabbar.Item>
            <React.$Vant.Tabbar.Item name="/business/index" icon={<React.$ICONS.ManagerO />}>我的</React.$Vant.Tabbar.Item>
        </React.$Vant.Tabbar>
    )
}

export default Menu