import React from "react";
import RegionData from '@/services/region.js'

const Profile = ()=>
{
    const [form] = React.$Vant.Form.useForm()

    let navigate = React.$Router.useNavigate()

    var cookie = React.$Cookies.load('business') ? React.$Cookies.load('business') : {}

    if(Object.getOwnPropertyNames(cookie).length > 0)
    {
        for(var key in cookie)
        {
           if(!cookie[key])
           {
            cookie[key] = ''
           }

           if(key == 'ava_text')
           {
            cookie[key] = [{
                url:cookie[key]
            }]
           }
        }
    }

    var [business,setBusiness] = React.useState(cookie)
    var [genderStatus,setGenderStatus] = React.useState(cookie.gender)

    var code = []

    if(cookie.province)
    {
        code.push(cookie.province)
    }

    if(cookie.city)
    {
        code.push(cookie.city)
    }

    if(cookie.district)
    {
        code.push(cookie.district)
    }

    const [region,SetRegion] = React.useState(code)

    const rules = {
        mobile: [
            {
              required: true,
              message: '请输入手机号码',
            },
            {
              pattern: /(^1[3|4|5|7|8][0-9]{9}$)/,
              message: '手机号码格式有误'
            }
        ],
        nickname: [
        {
            required: true,
            message: '请输入昵称',
        },
        ],
        email: [
        {
            required: true,
            message: '请输入邮箱',
        },
        {
            pattern: /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/,
            message: '邮箱格式有误'
        }
        ],
      }

      const [ShowGender,setGender] = React.useState(false)

      const GenderList = [
        {text:"保密",value:'0'},
        {text:"男",value:'1'},
        {text:"女",value:'2'},
      ]

      const GenderSelect = (value,arr) =>
      {
        setGender(false)

        if(arr)
        {
            console.log(arr);
            form.setFieldsValue({
                'gender_text' : arr.text,
                'gender':arr.value
            })
        }
      }

      const profile = async values =>
      {
        var data = {
            id:business.id,
            mobile:values.mobile,
            nickname:values.nickname,
            email:values.email,
        }

        switch(values.gender_text)
        {
            case "保密":
                data.gender = '0'
                break;
            
            case "男":
                data.gender = '1'
                break;
            
            case "女":
                data.gender = '2'
                break;
            default:
                data.gender = '0'
        }

        //密码不为空时追加
        if(values.password)
        {
            data.password = values.password
        }

        var avatar = values.ava_text[0].file

        if(avatar)
        {
            data.avatar = avatar
        }

        //判断地区数据
        if(region)
        {
            data.region = region[region.length-1]
        }
        
        var result = await React.$HTTP.UPLOAD({
            url:'/business/profile',
            params:data
        })

        if(result.code == 0)
        {
            React.$Vant.Toast.fail(result.msg)
            return false
        }

        React.$Vant.Toast.success({
            message:result.msg,
            onClose:()=>
            {
                React.$Cookies.save('business',result.data)
                navigate(-1)
            }
        })
      }

      return (
        <>
        <link rel="stylesheet" href="/assets/css/profile.css" type="text/css" />

        <React.$Vant.NavBar
            title="基本资料"
            onClickLeft={()=>navigate(-1)}
        />

        <div className="wo">
            <img src={business.ava_text[0].url} />
            <p><a>{business.nickname}</a></p>
        </div>
            
        <React.$Vant.Form
            form={form}
            onFinish={profile}
            initialValues={business}
            footer={
                <div style={{margin:'16px 16px 0'}}>
                    <React.$Vant.Button round nativeType='submit' type='primary' block>
                        修改资料    
                    </React.$Vant.Button>
                </div>
            }
        >
            <React.$Vant.Form.Item
                name='nickname'
                label='昵称'
                rules={rules.nickname}
            >
                <React.$Vant.Input placeholder='请输入昵称'/>  
            </React.$Vant.Form.Item>
            
            <React.$Vant.Form.Item
                name='mobile'
                label='手机号码'
                rules={rules.mobile}
            >
                <React.$Vant.Input placeholder='请输入手机号码'/>  
            </React.$Vant.Form.Item>
            
            <React.$Vant.Form.Item
                name='email'
                label='邮箱'
                rules={rules.email}
            >
                <React.$Vant.Input placeholder='请输入邮箱'/>  
            </React.$Vant.Form.Item>
            
            <React.$Vant.Form.Item
                name='password'
                label='密码'
                rules={rules.password}
            >
                <React.$Vant.Input type='password' placeholder='输入则修改密码'/>  
            </React.$Vant.Form.Item>
            
            <React.$Vant.Form.Item
                name='gender_text'
                label='性别'
                rules={rules.gender_text}
                onClick={()=>setGender(true)}
            >
                <React.$Vant.Input placeholder='请选择性别' readOnly/>  
            </React.$Vant.Form.Item>

            <React.$Vant.Popup visible={ShowGender} onClose={()=>setGender(false)} position='bottom'>
                <React.$Vant.Picker
                    defaultValue={business.gender}
                    columns={GenderList}
                    onConfirm={GenderSelect}
                    onCancel={()=>setGender(false)}
                />
            </React.$Vant.Popup>
            
            <React.$Vant.Form.Item
                name='ava_text'
                label='头像'
            >
                <React.$Vant.Uploader maxCount={1}/>  
            </React.$Vant.Form.Item>
            
            <React.$Vant.Form.Item
                label='地区'
                isLink
            >
                <React.$Vant.Cascader
                    popup
                    value={region}
                    onFinish={SetRegion}
                    title='选择地区'
                    options={RegionData}
                >
                    {(_,selectedRows,actions)=>(
                        <React.$Vant.Input
                            value={selectedRows.map(el=>el.text).join(',')}
                            readOnly
                            placeholder='请选择所在地区'
                            onClick={() => actions.open()}
                        />
                    )}
                </React.$Vant.Cascader>
            </React.$Vant.Form.Item>

        </React.$Vant.Form>
        </>
      )
}

export default Profile