import './App.css';

//引入路由
import { BrowserRouter,Route,Routes,Outlet,NavLink } from 'react-router-dom';
//路由守卫
import AuthRouter from './auth';

//引入组件
import Home from '@/components/Home.js' 
import Register from '@/components/Register.js' 
import Login from '@/components/Login.js' 


import BusinessIndex from '@/components/business/Index.js'
import BusinessProfile from '@/components/business/Profile.js'
import BusinessOrder from '@/components/business/Order.js'
import BusinessCoupon from '@/components/business/Coupon.js'

import ProjectInfo from '@/components/project/Info.js'
import Coupon from '@/components/Coupon'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* 默认首页 */}
          <Route path='/' element={<Home />}></Route>

          {/* 嵌套路由写法 */}
          <Route path='/' element={<Outlet />}>
            <Route path='business' element={<Outlet />}>
              <Route path='index' element={<AuthRouter auth={true} component={<BusinessIndex />}/> }></Route>
              <Route path='profile' element={<AuthRouter auth={true} component={<BusinessProfile />} />}></Route>
              <Route path='order' element={<AuthRouter auth={true} component={<BusinessOrder />} />}></Route>
              <Route path='coupon' element={<AuthRouter auth={true} component={<BusinessCoupon />} />}></Route>
            </Route>
          </Route>

          {/* 嵌套路由  */}
          <Route path='/' element={<Outlet />}>
            <Route path='project' element={<Outlet/>}>
              <Route path='info' element={<ProjectInfo />}></Route>
            </Route>
          </Route>

          <Route path='/coupon' element={<AuthRouter auth={true} component={<Coupon/>} />}></Route>

          <Route path='/register' element={<Register />}></Route>
          <Route path='/login' element={<Login />}></Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
