import React,{useState} from 'react'

const Info = () =>
{
    let navigate = React.$Router.useNavigate()

    const {state} = React.$Router.useLocation()

    // 状态渲染
    const [id,SetID] = React.useState(state.id)
    const [info,SetInfo] = React.useState({})

    const [price,SetPrice] = React.useState('')
    const [sign,setSign] =React.useState(false)

    // 获取cookie
    var cookie = React.$Cookies.load('business') ? React.$Cookies.load('business') : {}
    var [business,SetBusiness] = React.useState(cookie)

    // 倒计时
    const [down,SetDown] = React.useState(0)
    const [msg,SetMsg] = React.useState('')

    // 优惠券使用状态
    const [visible,setVisible] = React.useState(false)
    const [chosenCoupon,setChosenCoupon] = React.useState(-1)

    //优惠券列表
    const [list,SetList] = React.useState([])
    const [dislist,SetDisList] = React.useState([])

    

    //切换优惠券
    const CouponChange = async (index) =>
    {
        setVisible(false)

        setChosenCoupon(index)

        var value = list[index] ? list[index].value : 0
            value = value/100

        var price = info.price - value
            price = price.toFixed(2)
        SetPrice(price)
    }

    // hook钩子函数 生命周期作用
    React.useEffect(()=>{
        InfoLoad()
        signstatus()
    },[])

    //报名状态
    const signstatus = async () =>
    {
        var data = {
            busid:business.id,
            pid:id
        }

        var result = await React.$HTTP.POST({
            url:'/project/project/check',
            params:data
        })

        if(result.code == 0)
        {
            React.$Vant.Toast.fail(result.msg)
            return false
        }
        setSign(result.data)
    }

    //活动请求
    const InfoLoad = async ()=>
    {
        var result = await React.$HTTP.POST({
            url:'/project/project/info',
            params:{id:id}
        })

        if(result.code == 0)
        {
            React.$Vant.Toast.fail({
                message:result.msg,
                onClose:()=>{
                    navigate(-1)
                }
            })
            return false
        }

        SetInfo(result.data)
        SetPrice(result.data.price)

        if(result.data.status < '3')
        {
            //活动没开始
            var createtime = result.data.createtime*1000
            var now = new Date().getTime()
            var res = createtime - now <= 0 ? 0 : createtime - now
            SetDown(res)
            SetMsg('距离活动开始')
        }else{
            //活动开始
            var endtime = result.data.endtime*1000
            var now = new Date().getTime()
            var res = endtime - now <= 0  ? 0 : endtime - now;
            SetDown(res)
            SetMsg('距离活动结束') 
        }

        // 如果用户信息不为空，就去请求优惠券列表
        if(Object.getOwnPropertyNames(business).length > 0)
        {
        CouponLoad(result.data)
        }
        
    }

    console.log(info);

    //优惠券组件
    const CouponLoad = async (project)=>
    {
        var result = await React.$HTTP.POST({
            url:'/coupon/receive/index',
            params:{busid:business.id}
        })

        if(result.code == 0)
        {
            React.$Vant.Toast.fail(result.msg)
            return false
        }

        var list1 = []
        var list2 = []

        result.data.map((item) => {
            var update = parseFloat(project.price) * parseFloat(item.coupon.discount)
            update = parseFloat((project.price - update)*100)
            var desc = parseFloat(item.coupon.discount)*10
            desc = desc.toFixed(1)

            var coupon = {
                id:item.id.toString(),
                name:item.coupon.title,
                condition:` `,
                startAt:item.coupon.createtime,
                endAt:item.coupon.endtime,
                value: update,
                valueDesc:desc,
                unitDesc: '折'
            }

            if(parseFloat(project.price) >= parseFloat(item.coupon.requirement))
            {
                list1.push(coupon)
            }else{
                coupon.reason = coupon.description
                list2.push(coupon)
            }
        })

        SetList(list1)
        SetDisList(list2)
    }

    //倒计时条件判断
    const ProjectCountDown = ()=>
    {
        if(down > 0)
        {
            return (
                <div className='down'>  
                    <div className='left'>{msg}</div>
                    <div className='time'>
                        <React.$Vant.CountDown className='time' time={down} millisecond format="HH:mm:SS">
                            {(timeData) =>(
                                <>
                                    <span className='day'>{timeData.days}</span>天
                                    <span className='hour'>{timeData.hours}</span>时
                                    <span className='min'>{timeData.minutes}</span>分
                                    <span className='sec'>{timeData.seconds}</span>秒
                                </>
                            )}    
                        </React.$Vant.CountDown>
                    </div>
                </div>
            )
        }else{
            return (<></>)
        }
    }

    const ProjectButton = () =>
    {
        if(info.status == '1')
        {
            return (
                <React.$Vant.Button round type='primary' block className='button' onClick={submit}>
                    {sign ? '已报名' : '立即预约'} 
                </React.$Vant.Button>
            )
        }else{
            return (
                <a className='button'>{info.status_text}</a>
            )
        }
    }

    const clickCoupon = ()=>
    {
        setVisible(true)
        if(Object.getOwnPropertyNames(business).length > 0)
        {
            //登录状态 显示优惠券
            CouponLoad(info)
        }
    }

    const submit = ()=>
    {
        if(Object.getOwnPropertyNames(business).length <= 0)
        {
            React.$Vant.Toast.fail('请先登录')
            return false
        }

        if(sign)
        {
            React.$Vant.Toast.fail('已报名，请勿重复操作')
            return false
        }

        React.$Vant.Dialog.confirm({
            title:'预约提醒',
            message:'是否确认预约该活动'
        })
        .then(async ()=>{
            var cid = list[chosenCoupon] ? list[chosenCoupon].id : 0;

            var data = {
                pid:info.id,
                busid:business.id,
                cid:cid
            }

            var result = await React.$HTTP.POST({
                url:'/project/order/add',
                params:data
            })

            if(result.code == 0)
            {
                React.$Vant.Toast.fail(result.msg)
                return false
            }

            React.$Vant.Toast.success({
                message:result.msg,
                onClose:()=>{
                    navigate(result.url)
                }
            })
        })
        .catch(()=>{})
    }

    return (
        <>
        <link rel="stylesheet" href="/assets/css/info.css" type="text/css" />

        <React.$Vant.NavBar
            title='活动详情'
            onClickLeft={() => navigate(-1)}
        />

        <div className='banner_box swiper-container'>
            <img src={info.thumb_text} /> 
        </div>

        <div className='info'>
            <h3 className='title'>{info.title}</h3>
            <div className='base'>
                <div className='price'>￥{price}</div>
                <div className='join'>
                    <span>已预约：{info.count_text}人</span>
                </div>
            </div>
        </div>

        {/* 倒计时 */}
         <ProjectCountDown />

        {/* 优惠券  */}
        <React.$Vant.CouponCell
            coupons={list}
            chosenCoupon={chosenCoupon}
            onClick={clickCoupon}
        >
        </React.$Vant.CouponCell>

        <React.$Vant.Popup
            round 
            position='bottom'
            visible={visible}
            onClose={()=>setVisible(false)}
        >
            <React.$Vant.CouponList
                chosenCoupon={chosenCoupon}
                showExchangeBar={false}
                coupons={list}
                disabledCoupons={dislist}
                onChange={CouponChange}
            >
            </React.$Vant.CouponList>
        </React.$Vant.Popup>

        {/* 选项信息 */}
        <div className='option'>
            <div className='title'>活动时间</div>
            <div className='desc'>
                {info.createtime_text} - {info.endtime_text}
            </div>
        </div>
        
        {/* 详细信息 */}
        <div className='detail'>
            <div className='title'>
                <div className='line left'></div>
                <div className='center'>详情</div>
                <div className='line right'></div>
            </div>
            <div className='content' dangerouslySetInnerHTML={{__html:info.content}}></div>
        </div>

        <div className='am-navbar gm-foot am-no-layout footer'>
            <ProjectButton />
        </div>
        </>
    )
}

export default Info 