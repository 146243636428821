import React from "react";

const Coupon = ()=>
{
    const navigate = React.$Router.useNavigate()

    const cookie = React.$Cookies.load('business')
    const [business,setBusiness] = React.useState(cookie)
    const [list,setList] = React.useState([])

    React.useEffect(()=>
    {
        datalist()
    },[])

    //data
    const datalist = async ()=>
    {
        var result = await React.$HTTP.POST({
            url:'/projectbusiness/order/coupon',
            params:{busid:business.id}
        })

        if(result.code == 0)
        {
            React.$Vant.Toast.fail(result.msg)
            return false
        }

        setList(result.data)
        // console.log(result.data);
    }

    const CouponList = ()=>
    {
        return list.map((item,key)=>{
            return (
                <div className="order1" key={key}>
                    <div className="order_title1">
                        <label>{item.coupon.title}</label>
                        <label>{item.coupon.requirement <= 0 ? '无门槛' : `满￥${item.coupon.requirement} 可用`}</label>
                    </div>
                    <div className="order_text">
                        <h3>
                            领取时间：{item.createtime_text}<br/>
                            过期时间：{item.coupon.endtime_text}
                        </h3>
                        
                        <p>{(item.coupon.discount*10).toFixed(1)}折</p>
                    </div> 
                </div>
            )
        })
    }


    return (
        <>
        <link rel="stylesheet" href="/assets/css/order.css" type="text/css" />
            <React.$Vant.NavBar
                title="我的优惠券"
                onClickLeft={()=>navigate(-1)}
            />

            <div className="move_box">
                <div className="move">
                    <div className="order_box">
                        <CouponList />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Coupon