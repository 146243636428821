import React from "react";

const Coupon = ()=>
{
    let navigate = React.$Router.useNavigate()

    var cookie = React.$Cookies.load('business') ? React.$Cookies.load('business') : {}
    var [business,SetBusiness] = React.useState(cookie)

    const [list,SetList] = React.useState([])

    React.useEffect(()=>{
        CouponLoad()
    },[])

    //优惠券列表
    const CouponLoad = async () =>
    {
        var result = await React.$HTTP.POST({
            url:'/coupon/coupon/index',
            params:{busid:business.id}
        })

        if(result.code == 0)
        {
            React.$Vant.Toast.fail(result.msg)
            return false
        }

        SetList(result.data)
    }

    // 领取优惠券
    const CouponReceive = async (cid)=>
    {
        React.$Vant.Dialog.confirm({
            title:'领取提示',
            message:'是否确认领取优惠券'
        })
        .then(async ()=>{
            var data = {
                busid:business.id,
                cid:cid
            }

            var result = await React.$HTTP.POST({
                url:'/coupon/receive/add',
                params:data
            })

            if(result.code == 0)
            {
                React.$Vant.Toast.fail(result.msg)
                return false
            }

            React.$Vant.Toast.success({
                message:result.msg,
                duration:500,
                forbidClick:true,
                onClose:()=>{
                    CouponLoad()
                }
            })
        })
        .catch(()=>{})
    }


    //领取按钮
    const ReceiveButton = (item)=>
    {
        if(item.count_text >= item.nums)
        {
            return (
                <React.$Vant.Button disabled size="small" className="cancel" type='info'>已抢完</React.$Vant.Button>
            )
        }else{
            return (
                item.receive ? 
                <React.$Vant.Button disabled size="small" className='cancel' type='warning'>已领取</React.$Vant.Button> :
                <React.$Vant.Button onClick={()=>{CouponReceive(item.id)}} size="small" className='cancel' type='primary'>立即抢</React.$Vant.Button>
            )
        }
    }

    // 优惠券信息
    const CouponList = ()=>
    {
        return list.map((item,key)=>{
            return (
                <div className="order" key={key}> 
                    <div className="order_title">{item.title}</div>
                    <div className="order_text">
                        <h3>
                        开始时间：{item.createtime_text}<br />
                        结束时间：{item.endtime_text}
                        </h3>
                        <h2>{item.requirement <= 0 ? '无门槛' : `满 ￥${item.requirement} 元可用`}</h2>
                        <h2>限量 {item.nums} 张</h2>
                        <p>{(item.discount * 10).toFixed(1)}折</p>
                    </div>

                    {ReceiveButton(item)}
                </div>
            )
        })
    }

    return (
        <>
        <link rel="stylesheet" href="/assets/css/order.css" type="text/css" />

        <React.$Vant.NavBar
            title="优惠券"
            onClickLeft={()=>{navigate(-1)}}
        />

        <div className="move_box">
            <div className="move">
                <div className="order_box">
                    <CouponList />
                </div>
            </div>
        </div>

        </>
    )

}

export default Coupon