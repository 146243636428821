import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//全局引入
import * as Vant from 'react-vant'
import * as Icons from '@react-vant/icons'
//路由
import * as router from 'react-router-dom'
// 接口请求
import request from '@/services/request.js'
//cookie
import cookies from 'react-cookies'


//添加在全局框架属性
React.$Vant = Vant
React.$ICONS = Icons

React.$Router = router

React.$HTTP = request

React.$Cookies = cookies

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
