import React from "react";

const Login = () =>
{
    let navigate = React.$Router.useNavigate()

    const rules = {
        mobile: [
            {
            required: true,
            message: '请输入手机号码',
            },
            {
            pattern: /(^1[3|4|5|7|8][0-9]{9}$)/,
            message: '手机号码格式有误'
            }
        ],
        password: [
            {
            required: true,
            message: '请输入密码',
            },
            {
            pattern: /.{3,}/,
            message: '密码至少3位以上'
            }
        ]
        }

    const log = async (values)=>
    {
        var data = {
            mobile:values.mobile,
            password:values.password
        };

        var result = await React.$HTTP.POST({
            url:'/business/login',
            params:data
        })

        if(result.code == 0 )
        {
            React.$Vant.Toast.fail(result.msg)
            return false
        }
        React.$Vant.Toast.success({
            message:result.msg,
            onClose:()=>{
                React.$Cookies.save('business',result.data)

                navigate('/business/index')
            }
        })
        
    }

    return (
        <>
            <React.$Vant.NavBar
                title="登录"
                onClickLeft={()=>navigate(-1)}
            />

            <div className="logo"><img src="/assets/images/logo.png" /></div>

            <React.$Vant.Form
                onFinish={log}
                initialValues={{
                    mobile:13666666666,
                }}
                footer={
                    <div style={{margin: '16px 16px 0'}}>
                        <React.$Vant.Button round nativeType='submit' type='primary' block>
                            登录
                        </React.$Vant.Button>
                    </div>
                }
            >
                <React.$Vant.Form.Item 
                    name="mobile"
                    label='手机号码'
                    rules={rules.mobile}
                >
                    <React.$Vant.Input placeholder="请输入手机号码" />    
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item 
                    name="password"
                    label='密码'
                    rules={rules.password}
                >
                    <React.$Vant.Input type='password' placeholder="请输入密码" />    
                </React.$Vant.Form.Item>

            </React.$Vant.Form>

            <p className="login-text">
                没有账号？点击
                <React.$Router.NavLink to="/register" className="reg">注册</React.$Router.NavLink> 
            </p>
        </>
    )
}

export default Login